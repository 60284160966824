import React from 'react';

const Success = () => {
  const companyLogoUrl = 'https://sosonweb.com/Content/images/logo.png';  // Replace with your actual logo URL
  const successImageUrl = 'https://via.placeholder.com/300';  // Replace with your actual success image URL

  return (
    <div className="container mt-5 text-center">
      {/* Company Logo */}
      <img src={companyLogoUrl} alt="Company Logo" className="img-fluid mb-4" style={{ maxHeight: '100px' }} />

      {/* Success Message */}
      <h1 className="display-4 text-success">Thank You!</h1>
      <p className="lead">Your information has been successfully sent to SOS!</p>

      {/* Success Image */}
      <div className="mb-4">
        <img src={successImageUrl} alt="Success" className="img-fluid" style={{ maxHeight: '200px' }} />
      </div>

      {/* Final Message */}
      <p className="mb-4">We will get back to you soon. Have a great day!</p>

      {/* Optional: Add a button to return to home */}
      <a href="/" className="btn btn-primary">Return to Home</a>
    </div>
  );
};

export default Success;
