import React, { useState } from 'react';
import axios from 'axios';

const SearchInterview = ({ onInterviewSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const backendUrl =
    process.env.REACT_APP_BACKEND_URL ||
    'https://backend-alb-1529460594.us-east-1.elb.amazonaws.com';

  const handleSearch = () => {
    if (!searchQuery.trim()) {
      setError('Please enter a valid search query.');
      return;
    }

    setLoading(true);
    setError('');
    axios
      .get(
        `${backendUrl}/api/search-interview?query=${encodeURIComponent(
          searchQuery
        )}`
      )
      .then((response) => {
        if (response.data.length === 0) {
          setError('No interviews found for the given search query.');
          setSearchResults([]);
        } else {
          setSearchResults(response.data);
        }
      })
      .catch((err) => {
        setError(
          'An error occurred while searching for interviews. Please try again.'
        );
        console.error('Search error:', err);
        setSearchResults([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="container mt-5">
      <h2>Search for Your Interview</h2>
      <div className="form-group">
        <label htmlFor="searchQuery">Enter Email or Interview ID</label>
        <input
          type="text"
          id="searchQuery"
          className="form-control"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          aria-label="Search Query"
        />
      </div>
      <button
        className="btn btn-primary"
        onClick={handleSearch}
        disabled={loading}
      >
        {loading ? 'Searching...' : 'Search'}
      </button>

      {error && <div className="alert alert-danger mt-3">{error}</div>}

      {searchResults.length > 0 && (
        <ul className="list-group mt-3">
          {searchResults.map((interview) => (
            <li key={interview.id} className="list-group-item">
              <strong>Client:</strong> {interview.client} <br />
              <strong>Interview Date:</strong> {interview.interviewDate} <br />
              <strong>Round:</strong> {interview.interviewRound} <br />
              <button
                className="btn btn-secondary mt-2"
                onClick={() => onInterviewSelect(interview)}
              >
                Edit Interview
              </button>
            </li>
          ))}
        </ul>
      )}

      {searchResults.length === 0 && !error && !loading && (
        <div className="mt-3 text-muted">No search results to display.</div>
      )}
    </div>
  );
};

export default SearchInterview;
