import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';  
import ConsultingForm from './components/ConsultingForm';
import InterviewForm from './components/InterviewForm';
import SearchInterview from './components/SearchInterview';  // New search component
import Success from './components/Success';

const Home = () => {
  const companyLogoUrl = 'https://sosonweb.com/Content/images/logo.png';  // Company logo URL

  return (
    <div className="container mt-5">
      <div className="text-center mb-4">
        <img
          src={companyLogoUrl}
          alt="Company Logo"
          className="img-fluid mb-3"
          style={{ maxHeight: '100px' }}
        />
      </div>
      <h1 className="text-center">Welcome to SOS</h1>
      <p className="text-center">Please choose the form you would like to fill out:</p>
      <div className="d-flex justify-content-center">
        <Link to="/consulting" className="btn btn-primary mx-3">Consulting Form</Link>
        <Link to="/interview" className="btn btn-secondary mx-3">Interview Form</Link>
        <Link to="/search" className="btn btn-info mx-3">Search Interviews</Link> {/* New link for search */}
      </div>
    </div>
  );
};

function App() {
  const [selectedInterview, setSelectedInterview] = useState(null); // State to store selected interview

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />  {/* Home page offering choice */}
        <Route path="/consulting" element={<ConsultingForm />} />  {/* ConsultingForm route */}
        <Route 
          path="/interview" 
          element={<InterviewForm selectedInterview={selectedInterview} />} 
        />  {/* Pass selectedInterview to InterviewForm */}
        <Route path="/search" element={<SearchInterview onInterviewSelect={setSelectedInterview} />} />  {/* Search for interviews */}
        <Route path="/success" element={<Success />} />  {/* Success route */}
      </Routes>
    </Router>
  );
}

export default App;
