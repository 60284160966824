import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  FaUser,
  FaPhone,
  FaCalendar,
  FaUniversity,
  FaLink,
  FaFileAlt,
  FaLaptop,
  FaIdCard,
} from 'react-icons/fa';

const ConsultingForm = () => {
  const navigate = useNavigate();
  const [backendUrl, setBackendUrl] = useState('');
  const [submitError, setSubmitError] = useState('');
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0); // Track the current form page

  const companyLogoUrl =
    process.env.REACT_APP_COMPANY_LOGO_URL ||
    'https://sosonweb.com/Content/images/logo.png';

  useEffect(() => {
    const url = 'https://backend-alb-1529460594.us-east-1.elb.amazonaws.com';
    setBackendUrl(url);
  }, []);

  const validationSchema = Yup.object().shape({
    // Page 0
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    currentEmail: Yup.string()
      .email('Invalid email format')
      .required('Current Email Address is required'),
    currentPhone: Yup.string().required('Current Phone Number is required'),
    dob: Yup.string().required('Date of Birth is required'),
    referrerFullName: Yup.string().required(
      'Full Name of Person Who Referred You is required'
    ),
    knownPeopleAtSOS: Yup.string(),

    // Page 1
    degreeSelection: Yup.string().required('Select Degree is required'),
    bachelorsUniversity: Yup.string().when('degreeSelection', {
      is: 'bachelors',
      then: Yup.string().required('Bachelors University Name is required'),
    }),
    bachelorsDegree: Yup.string().when('degreeSelection', {
      is: 'bachelors',
      then: Yup.string().required('Bachelors Degree is required'),
    }),
    bachelorsSpecialization: Yup.string().when('degreeSelection', {
      is: 'bachelors',
      then: Yup.string().required('Specialization is required'),
    }),
    bachelorsDate: Yup.string().when('degreeSelection', {
      is: 'bachelors',
      then: Yup.string().required('Degree Date is required'),
    }),
    mastersUniversity: Yup.string().when('degreeSelection', {
      is: 'masters',
      then: Yup.string().required('Masters University Name is required'),
    }),
    mastersDegree: Yup.string().when('degreeSelection', {
      is: 'masters',
      then: Yup.string().required('Masters Degree is required'),
    }),
    mastersSpecialization: Yup.string().when('degreeSelection', {
      is: 'masters',
      then: Yup.string().required('Specialization is required'),
    }),
    mastersDate: Yup.string().when('degreeSelection', {
      is: 'masters',
      then: Yup.string().required('Degree Date is required'),
    }),

    // Page 2
    optStatus: Yup.string().required('OPT Status is required'),
    optApplicationDate: Yup.string().when('optStatus', {
      is: 'applied',
      then: Yup.string().required('OPT Application Date is required'),
    }),
    optReceiveDate: Yup.string().when('optStatus', {
      is: 'applied',
      then: Yup.string().required('Expected Receive Date is required'),
    }),
    optStartDate: Yup.string().when('optStatus', {
      is: 'onOpt',
      then: Yup.string().required('OPT Start Date is required'),
    }),
    optEndDate: Yup.string().when('optStatus', {
      is: (val) => val === 'onOpt' || val === 'onOptExtension',
      then: Yup.string().required('OPT End Date is required'),
    }),

    // Page 3
    linkedInUrl: Yup.string().url('Invalid LinkedIn URL'),
    resume: Yup.mixed().required('Current Resume is required'),
    programmingExperience: Yup.string(),
    projectDescription: Yup.string(),
    careerPath: Yup.string().required('Career Path is required'),
    marketingInterest: Yup.string().required(
      'Select whether you are interested in remote marketing or in-person training'
    ),

    // Page 4
    wasAtConsultancy: Yup.string().required(
      'Please indicate if you were at another consultancy'
    ),
    previousConsultancy: Yup.string().when('wasAtConsultancy', {
      is: 'yes',
      then: Yup.string().required('Previous Consultancy is required'),
    }),
    previousConsultancyDuration: Yup.string().when('wasAtConsultancy', {
      is: 'yes',
      then: Yup.string().required('Previous Consultancy Duration is required'),
    }),
    leavingReason: Yup.string().when('wasAtConsultancy', {
      is: 'yes',
      then: Yup.string().required('Reason for Leaving is required'),
    }),
  });

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append('name', `${values.firstName} ${values.lastName}`);
    formData.append('email', values.currentEmail);
    formData.append('formType', 'consulting');

    Object.keys(values).forEach((key) => {
      if (key !== 'resume' && values[key] !== null) {
        formData.append(key, values[key]);
      }
    });

    if (values.resume) {
      formData.append('resume', values.resume);
    }

    axios
      .post(`${backendUrl}/api/submit-form`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setSubmitSuccess(true);
        resetForm();
        setTimeout(() => navigate('/success'), 3000);
      })
      .catch((error) => {
        console.error('Form submission error:', error);
        setSubmitError(
          'An error occurred while submitting the form. Please try again.'
        );
        setSubmitting(false);
      })
      .finally(() => setIsLoading(false));
  };

  const renderFormPage = (formik) => {
    const { values, setFieldValue } = formik;
    switch (page) {
      case 0:
        return (
          <>
            <div className="form-group">
              <label htmlFor="firstName">
                <FaUser /> First Name
              </label>
              <Field type="text" name="firstName" className="form-control" />
              <ErrorMessage
                name="firstName"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="middleName">
                <FaUser /> Middle Name
              </label>
              <Field type="text" name="middleName" className="form-control" />
              <ErrorMessage
                name="middleName"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">
                <FaUser /> Last Name
              </label>
              <Field type="text" name="lastName" className="form-control" />
              <ErrorMessage
                name="lastName"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="dob">
                <FaCalendar /> Date of Birth
              </label>
              <Field type="date" name="dob" className="form-control" />
              <ErrorMessage
                name="dob"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="currentPhone">
                <FaPhone /> Current Phone Number
              </label>
              <Field type="text" name="currentPhone" className="form-control" />
              <ErrorMessage
                name="currentPhone"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="currentEmail">
                <FaUser /> Current Email Address
              </label>
              <Field type="email" name="currentEmail" className="form-control" />
              <ErrorMessage
                name="currentEmail"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="referrerFullName">
                <FaUser /> Full Name of Person Who Referred You
              </label>
              <Field
                type="text"
                name="referrerFullName"
                className="form-control"
              />
              <ErrorMessage
                name="referrerFullName"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="knownPeopleAtSOS">
                <FaUser /> Who Do You Know at SOS?
              </label>
              <Field
                type="text"
                name="knownPeopleAtSOS"
                className="form-control"
              />
              <ErrorMessage
                name="knownPeopleAtSOS"
                component="div"
                className="text-danger"
              />
            </div>
            <button
              type="button"
              onClick={() => setPage(1)}
              className="btn btn-primary"
            >
              Next
            </button>
          </>
        );
      case 1:
        return (
          <>
            <div className="form-group">
              <label htmlFor="degreeSelection">Select Degree</label>
              <Field
                as="select"
                name="degreeSelection"
                className="form-control"
                onChange={(e) => {
                  setFieldValue('degreeSelection', e.target.value);
                }}
              >
                <option value="">Select Degree</option>
                <option value="bachelors">Bachelors</option>
                <option value="masters">Masters</option>
              </Field>
              <ErrorMessage
                name="degreeSelection"
                component="div"
                className="text-danger"
              />
            </div>

            {values.degreeSelection === 'bachelors' && (
              <>
                <div className="form-group">
                  <label htmlFor="bachelorsUniversity">
                    <FaUniversity /> Bachelors University Name
                  </label>
                  <Field
                    type="text"
                    name="bachelorsUniversity"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bachelorsUniversity"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="bachelorsDegree">
                    <FaUniversity /> Bachelors Degree
                  </label>
                  <Field
                    type="text"
                    name="bachelorsDegree"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bachelorsDegree"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="bachelorsSpecialization">
                    <FaUniversity /> Specialization
                  </label>
                  <Field
                    type="text"
                    name="bachelorsSpecialization"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bachelorsSpecialization"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="bachelorsDate">
                    <FaCalendar /> Degree Date
                  </label>
                  <Field
                    type="date"
                    name="bachelorsDate"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="bachelorsDate"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </>
            )}

            {values.degreeSelection === 'masters' && (
              <>
                <div className="form-group">
                  <label htmlFor="mastersUniversity">
                    <FaUniversity /> Masters University Name
                  </label>
                  <Field
                    type="text"
                    name="mastersUniversity"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mastersUniversity"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mastersDegree">
                    <FaUniversity /> Masters Degree
                  </label>
                  <Field
                    type="text"
                    name="mastersDegree"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mastersDegree"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mastersSpecialization">
                    <FaUniversity /> Specialization
                  </label>
                  <Field
                    type="text"
                    name="mastersSpecialization"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mastersSpecialization"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="mastersDate">
                    <FaCalendar /> Degree Date
                  </label>
                  <Field
                    type="date"
                    name="mastersDate"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="mastersDate"
                    component="div"
                    className="text-danger"
                  />
                </div>
              </>
            )}

            <button
              type="button"
              onClick={() => setPage(2)}
              className="btn btn-primary"
            >
              Next
            </button>
          </>
        );
      case 2:
        return (
          <>
            <div className="form-group">
              <label>OPT Status</label>
              <div role="group" aria-labelledby="optStatus">
                <label>
                  <Field
                    type="radio"
                    name="optStatus"
                    value="applied"
                  />{' '}
                  I have applied for OPT
                </label>
                <label>
                  <Field type="radio" name="optStatus" value="onOpt" /> I am on
                  OPT
                </label>
                <label>
                  <Field
                    type="radio"
                    name="optStatus"
                    value="onOptExtension"
                  />{' '}
                  I am on OPT Extension
                </label>
              </div>
              <ErrorMessage
                name="optStatus"
                component="div"
                className="text-danger"
              />

              {values.optStatus === 'applied' && (
                <>
                  <div className="form-group">
                    <label htmlFor="optApplicationDate">
                      OPT Application Date
                    </label>
                    <Field
                      type="date"
                      name="optApplicationDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="optApplicationDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="optReceiveDate">
                      Expected Receive Date
                    </label>
                    <Field
                      type="date"
                      name="optReceiveDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="optReceiveDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </>
              )}

              {values.optStatus === 'onOpt' && (
                <>
                  <div className="form-group">
                    <label htmlFor="optStartDate">OPT Start Date</label>
                    <Field
                      type="date"
                      name="optStartDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="optStartDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="optEndDate">OPT End Date</label>
                    <Field
                      type="date"
                      name="optEndDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="optEndDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </>
              )}

              {values.optStatus === 'onOptExtension' && (
                <>
                  <div className="form-group">
                    <label htmlFor="optEndDate">OPT End Date</label>
                    <Field
                      type="date"
                      name="optEndDate"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="optEndDate"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </>
              )}
            </div>

            <button
              type="button"
              onClick={() => setPage(3)}
              className="btn btn-primary"
            >
              Next
            </button>
          </>
        );
      case 3:
        return (
          <>
            <div className="form-group">
              <label htmlFor="linkedInUrl">
                <FaLink /> LinkedIn Profile URL
              </label>
              <Field type="url" name="linkedInUrl" className="form-control" />
              <ErrorMessage
                name="linkedInUrl"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="resume">
                <FaFileAlt /> Upload Your Resume
              </label>
              <input
                id="resume"
                name="resume"
                type="file"
                className="form-control"
                onChange={(event) => {
                  formik.setFieldValue('resume', event.currentTarget.files[0]);
                }}
              />
              <ErrorMessage
                name="resume"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="programmingExperience">
                <FaLaptop /> Describe Any Programming Experience
              </label>
              <Field
                as="textarea"
                name="programmingExperience"
                className="form-control"
              />
              <ErrorMessage
                name="programmingExperience"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="projectDescription">
                <FaLaptop /> Describe Previous Projects
              </label>
              <Field
                as="textarea"
                name="projectDescription"
                className="form-control"
              />
              <ErrorMessage
                name="projectDescription"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="careerPath">
                <FaLaptop /> Choose Career Path
              </label>
              <Field as="select" name="careerPath" className="form-control">
                <option value="">Select Career Path</option>
                <option value="DevOps Cloud">DevOps Cloud</option>
                <option value="ServiceNow">ServiceNow</option>
                <option value="Identity (SailPoint/Okta)">
                  Identity (SailPoint/Okta)
                </option>
                <option value="Data Engineering">Data Engineering</option>
                <option value="Fullstack">Fullstack</option>
              </Field>
              <ErrorMessage
                name="careerPath"
                component="div"
                className="text-danger"
              />
            </div>
            <div className="form-group">
              <label htmlFor="marketingInterest">
                <FaIdCard /> Remote Marketing or In-Person Training?
              </label>
              <Field
                as="select"
                name="marketingInterest"
                className="form-control"
              >
                <option value="">Select Option</option>
                <option value="Remote Marketing">Remote Marketing</option>
                <option value="In-Person Training in Dallas, TX">
                  In-Person Training in Dallas, TX
                </option>
                <option value="In-Person Training in Dublin, CA">
                  In-Person Training in Dublin, CA
                </option>
              </Field>
              <ErrorMessage
                name="marketingInterest"
                component="div"
                className="text-danger"
              />
            </div>

            <button
              type="button"
              onClick={() => setPage(4)}
              className="btn btn-primary"
            >
              Next
            </button>
          </>
        );
      case 4:
        return (
          <>
            <div className="form-group">
              <label>Were You Already at Another Consultancy?</label>
              <div role="group" aria-labelledby="wasAtConsultancy">
                <label>
                  <Field type="radio" name="wasAtConsultancy" value="yes" /> Yes
                </label>
                <label>
                  <Field type="radio" name="wasAtConsultancy" value="no" /> No
                </label>
              </div>
              <ErrorMessage
                name="wasAtConsultancy"
                component="div"
                className="text-danger"
              />

              {values.wasAtConsultancy === 'yes' && (
                <>
                  <div className="form-group">
                    <label htmlFor="previousConsultancy">
                      Previous Consultancy
                    </label>
                    <Field
                      type="text"
                      name="previousConsultancy"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="previousConsultancy"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="previousConsultancyDuration">
                      How Long Were You There?
                    </label>
                    <Field
                      type="text"
                      name="previousConsultancyDuration"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="previousConsultancyDuration"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="leavingReason">
                      Why Are You Leaving the Previous Consultancy?
                    </label>
                    <Field
                      type="text"
                      name="leavingReason"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="leavingReason"
                      component="div"
                      className="text-danger"
                    />
                  </div>
                </>
              )}
            </div>

            <button
              type="button"
              onClick={() => setPage(3)}
              className="btn btn-secondary"
            >
              Previous
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={formik.isSubmitting || isLoading}
            >
              {isLoading ? 'Submitting...' : 'Submit'}
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mt-5">
      <Formik
        initialValues={{
          // Page 0
          firstName: '',
          middleName: '',
          lastName: '',
          dob: '',
          currentPhone: '',
          currentEmail: '',
          referrerFullName: '',
          knownPeopleAtSOS: '',
          // Page 1
          degreeSelection: '',
          bachelorsUniversity: '',
          bachelorsDegree: '',
          bachelorsSpecialization: '',
          bachelorsDate: '',
          mastersUniversity: '',
          mastersDegree: '',
          mastersSpecialization: '',
          mastersDate: '',
          // Page 2
          optStatus: '',
          optApplicationDate: '',
          optReceiveDate: '',
          optStartDate: '',
          optEndDate: '',
          // Page 3
          linkedInUrl: '',
          resume: null,
          programmingExperience: '',
          projectDescription: '',
          careerPath: '',
          marketingInterest: '',
          // Page 4
          wasAtConsultancy: '',
          previousConsultancy: '',
          previousConsultancyDuration: '',
          leavingReason: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            {renderFormPage(formik)}
            {submitError && (
              <div className="alert alert-danger">{submitError}</div>
            )}
            {submitSuccess && (
              <div className="alert alert-success">
                Form submitted successfully! Redirecting...
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ConsultingForm;
