import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {
  FaUser,
  FaCalendar,
  FaClock,
  FaGlobe,
  FaFileAlt,
  FaClipboard,
  FaIdCard,
  FaEnvelope,
} from 'react-icons/fa';

const InterviewForm = ({ selectedInterview }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [backendUrl, setBackendUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const companyLogoUrl =
    process.env.REACT_APP_COMPANY_LOGO_URL ||
    'https://sosonweb.com/Content/images/logo.png';

  useEffect(() => {
    const url =
      process.env.REACT_APP_BACKEND_URL ||
      'https://backend-alb-1529460594.us-east-1.elb.amazonaws.com';
    setBackendUrl(url);
  }, []);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Full Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    marketingPersonEmail: Yup.string()
      .email('Invalid email format')
      .required('Marketing person email is required'),
    vendor: Yup.string().required('Vendor details are required'),
    client: Yup.string().required('Client details are required'),
    interviewDate: Yup.date().required('Interview date is required'),
    interviewTime: Yup.string().required('Interview time is required'),
    interviewTimezone: Yup.string().required('Timezone is required'),
    interviewRound: Yup.string().required('Interview round is required'),
    emailScreenshot: selectedInterview
      ? Yup.mixed()
      : Yup.mixed().required('Email screenshot is required'),
    jobDescriptionScreenshot: selectedInterview
      ? Yup.mixed()
      : Yup.mixed().required('Job description screenshot is required'),
    jobDescriptionText: Yup.string().required(
      'Job description text is required'
    ),
    feedback: Yup.string(),
    interviewHelpedBy: Yup.string(),
    interviewQuestions: Yup.string(),
  });

  // Handle form submission
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);

    if (selectedInterview) {
      // Update existing interview
      const url = `${backendUrl}/api/update-interview/${selectedInterview.id}`;
      axios
        .put(url, values)
        .then(() => {
          setSubmitSuccess(true);
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          setSubmitError(
            'An error occurred while updating the interview. Please try again.'
          );
          setSubmitting(false);
          console.error('Form submission error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // Create new interview
      const url = `${backendUrl}/api/submit-interview`;
      const formData = new FormData();

      // Append all fields except files
      Object.keys(values).forEach((key) => {
        if (
          key !== 'emailScreenshot' &&
          key !== 'jobDescriptionScreenshot' &&
          values[key]
        ) {
          formData.append(key, values[key]);
        }
      });

      // Append files if they exist
      if (values.emailScreenshot) {
        formData.append('emailScreenshot', values.emailScreenshot);
      }

      if (values.jobDescriptionScreenshot) {
        formData.append('jobDescriptionScreenshot', values.jobDescriptionScreenshot);
      }

      axios
        .post(url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          setSubmitSuccess(true);
          setSubmitting(false);
          resetForm();
        })
        .catch((error) => {
          setSubmitError(
            'An error occurred while submitting the form. Please try again.'
          );
          setSubmitting(false);
          console.error('Form submission error:', error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="container mt-5">
      {/* Form rendering code */}
      <div className="row justify-content-center">
        <div className="col-md-8 col-lg-6">
          <div className="card shadow-lg">
            <div className="card-body p-5">
              <div className="text-center mb-4">
                <img
                  src={companyLogoUrl}
                  alt="Company Logo"
                  className="img-fluid mb-3"
                  style={{ maxHeight: '100px' }}
                />
                <h1 className="mb-3">
                  {selectedInterview ? 'Edit Interview' : 'Add Your Interview Details'}
                </h1>
                <p className="text-muted">
                  {selectedInterview
                    ? 'Update your interview details below'
                    : 'Congrats on securing an interview!'}
                </p>
              </div>

              {submitError && (
                <div className="alert alert-danger">{submitError}</div>
              )}
              {submitSuccess && (
                <div className="alert alert-success">
                  Form submitted successfully!
                </div>
              )}

              <Formik
                initialValues={{
                  name: selectedInterview?.name || '',
                  email: selectedInterview?.email || '',
                  marketingPersonEmail:
                    selectedInterview?.marketingPersonEmail || '',
                  vendor: selectedInterview?.vendor || '',
                  client: selectedInterview?.client || '',
                  interviewDate: selectedInterview?.interviewDate || '',
                  interviewTime: selectedInterview?.interviewTime || '',
                  interviewTimezone: selectedInterview?.interviewTimezone || '',
                  interviewRound: selectedInterview?.interviewRound || '',
                  emailScreenshot: null,
                  jobDescriptionScreenshot: null,
                  jobDescriptionText:
                    selectedInterview?.jobDescriptionText || '',
                  feedback: selectedInterview?.feedback || '',
                  interviewHelpedBy: selectedInterview?.interviewHelpedBy || '',
                  interviewQuestions: selectedInterview?.interviewQuestions || '',
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize
              >
                {({ isSubmitting, setFieldValue }) => (
                  <Form>
                    {/* Full Name Field */}
                    <div className="form-group">
                      <label htmlFor="name">
                        <FaUser /> Full Name
                      </label>
                      <Field
                        type="text"
                        name="name"
                        className="form-control"
                        aria-label="Full Name"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Email Field */}
                    <div className="form-group">
                      <label htmlFor="email">
                        <FaEnvelope /> Email
                      </label>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        aria-label="Email"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Marketing Person Email Field */}
                    <div className="form-group">
                      <label htmlFor="marketingPersonEmail">
                        <FaUser /> Marketing Person Email
                      </label>
                      <Field
                        type="email"
                        name="marketingPersonEmail"
                        className="form-control"
                        aria-label="Marketing Person Email"
                      />
                      <ErrorMessage
                        name="marketingPersonEmail"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Vendor Field */}
                    <div className="form-group">
                      <label htmlFor="vendor">
                        <FaUser /> Vendor
                      </label>
                      <Field
                        type="text"
                        name="vendor"
                        className="form-control"
                        aria-label="Vendor"
                      />
                      <ErrorMessage
                        name="vendor"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Client Field */}
                    <div className="form-group">
                      <label htmlFor="client">
                        <FaUser /> Client
                      </label>
                      <Field
                        type="text"
                        name="client"
                        className="form-control"
                        aria-label="Client"
                      />
                      <ErrorMessage
                        name="client"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Date Field */}
                    <div className="form-group">
                      <label htmlFor="interviewDate">
                        <FaCalendar /> Interview Date
                      </label>
                      <Field
                        type="date"
                        name="interviewDate"
                        className="form-control"
                        aria-label="Interview Date"
                      />
                      <ErrorMessage
                        name="interviewDate"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Time Field */}
                    <div className="form-group">
                      <label htmlFor="interviewTime">
                        <FaClock /> Interview Time
                      </label>
                      <Field
                        type="time"
                        name="interviewTime"
                        className="form-control"
                        aria-label="Interview Time"
                      />
                      <ErrorMessage
                        name="interviewTime"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Timezone Field */}
                    <div className="form-group">
                      <label htmlFor="interviewTimezone">
                        <FaGlobe /> Interview Timezone
                      </label>
                      <Field
                        type="text"
                        name="interviewTimezone"
                        className="form-control"
                        aria-label="Interview Timezone"
                      />
                      <ErrorMessage
                        name="interviewTimezone"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Round Field */}
                    <div className="form-group">
                      <label htmlFor="interviewRound">
                        <FaIdCard /> Interview Round
                      </label>
                      <Field
                        as="select"
                        name="interviewRound"
                        className="form-control"
                        aria-label="Interview Round"
                      >
                        <option value="">Select Round</option>
                        <option value="1st Round">1st Round</option>
                        <option value="2nd Round">2nd Round</option>
                        <option value="Final Round">Final Round</option>
                      </Field>
                      <ErrorMessage
                        name="interviewRound"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Email Screenshot Field */}
                    <div className="form-group">
                      <label htmlFor="emailScreenshot">
                        <FaFileAlt /> Upload Email Screenshot
                      </label>
                      <input
                        id="emailScreenshot"
                        name="emailScreenshot"
                        type="file"
                        className="form-control"
                        aria-label="Upload Email Screenshot"
                        onChange={(event) => {
                          setFieldValue(
                            'emailScreenshot',
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="emailScreenshot"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Job Description Screenshot Field */}
                    <div className="form-group">
                      <label htmlFor="jobDescriptionScreenshot">
                        <FaFileAlt /> Upload Job Description Screenshot
                      </label>
                      <input
                        id="jobDescriptionScreenshot"
                        name="jobDescriptionScreenshot"
                        type="file"
                        className="form-control"
                        aria-label="Upload Job Description Screenshot"
                        onChange={(event) => {
                          setFieldValue(
                            'jobDescriptionScreenshot',
                            event.currentTarget.files[0]
                          );
                        }}
                      />
                      <ErrorMessage
                        name="jobDescriptionScreenshot"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Job Description Text Field */}
                    <div className="form-group">
                      <label htmlFor="jobDescriptionText">
                        <FaClipboard /> Job Description Text
                      </label>
                      <Field
                        as="textarea"
                        name="jobDescriptionText"
                        className="form-control"
                        aria-label="Job Description Text"
                      />
                      <ErrorMessage
                        name="jobDescriptionText"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Feedback Field */}
                    <div className="form-group">
                      <label htmlFor="feedback">
                        <FaClipboard /> Feedback
                      </label>
                      <Field
                        as="textarea"
                        name="feedback"
                        className="form-control"
                        aria-label="Feedback"
                      />
                      <ErrorMessage
                        name="feedback"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Helped By Field */}
                    <div className="form-group">
                      <label htmlFor="interviewHelpedBy">
                        <FaUser /> Interview Helped By
                      </label>
                      <Field
                        type="text"
                        name="interviewHelpedBy"
                        className="form-control"
                        aria-label="Interview Helped By"
                      />
                      <ErrorMessage
                        name="interviewHelpedBy"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    {/* Interview Questions Field */}
                    <div className="form-group">
                      <label htmlFor="interviewQuestions">
                        <FaClipboard /> Interview Questions
                      </label>
                      <Field
                        as="textarea"
                        name="interviewQuestions"
                        className="form-control"
                        aria-label="Interview Questions"
                      />
                      <ErrorMessage
                        name="interviewQuestions"
                        component="div"
                        className="text-danger"
                      />
                    </div>

                    <div className="text-center">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting || isLoading}
                      >
                        {isLoading
                          ? 'Submitting...'
                          : selectedInterview
                          ? 'Update Interview'
                          : 'Submit'}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterviewForm;
